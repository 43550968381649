/* global appConfig, PDFNet, CoreControls, SWRegistration */

import React, { useState } from 'react';
import * as CrytpoLib from './cryptojs';
import { UserTypeEnum, BinderItemType, FileTypeEnum, UsageType } from '@constants/common.constants';

// eslint-disable-next-line import/no-webpack-loader-syntax
//import scriptURL from 'sw-loader!../webworker/swpush.js';
import { GetURL } from './url';
import { userConstants } from '../constants/';
import { confirmAlert } from 'react-confirm-alert';
import { PDFDocument } from 'pdf-lib';
import Fingerprint2 from 'fingerprintjs2';
import moment from 'moment';
import {SettingStorage} from '../lib/indexeddb';
//TODO import logo from '@image/icon/placeholder-board.svg';
import logo from '../assets/images/icon/placeholder-board.svg';
import countryCodeLookup from 'country-code-lookup';

import * as DOMPurify from 'dompurify';

export const BLANK_GUID = "00000000-0000-0000-0000-000000000000"
export const BLANK_PDF = "123456278-9e44-497e-a94b-042badbfda78"
export const BLANK_VIDEO = "98765432-1e44-497e-a94b-042badbfda78"

import TeamsIcon from '@image/logo/microsoft-teams-1.svg';
import WebExIcon from '@image/logo/webex-logo-2.svg';
import ZoomIcon from '@image/logo/zoom-app.svg';
import { SmartlookWrapper } from './smartlookFunctions';

export const STATUS_ITEM = {
    DELETE: 0,
    NEW: 1,
    READ: 2,
    UPDATED: 3,
}

export function sanitizeString(s = "") {
  return DOMPurify.sanitize(s);
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function getMeetingLocIcon(name = '') {
  var icon, location, type;
  if (name !== "") {
    if (name.toLowerCase().indexOf("http") === 0) {
      type = 'web';
      if (name.toLowerCase().indexOf("webex.com") !== -1) {
        icon = <img style={{ transform: 'scale(3.2)' }} src={WebExIcon} />
        location = <label>WebEx Video Conference link</label>
      } else if (name.toLowerCase().indexOf("zoom.us") !== -1) {
        icon = <img src={ZoomIcon} />
        location = <label>Zoom Video Conference link</label>
      } else if (name.toLowerCase().indexOf("teams.microsoft") !== -1) {
        icon = <img src={TeamsIcon} />
        location = <label>Microsoft Teams Video Conference link</label>
      } else
        location = <label>External link</label>
    }
  }
  return { icon, location, type };
}

export const userConstructor = (callback = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if(hasBeenCalled) return;
  callback();
  setHasBeenCalled(true);
}

export function correctUserType(userType){
  switch(userType){
    case 'User': return 'boardUser'
    case UserTypeEnum.Publish: return 'boardAdmin'
    case UserTypeEnum.Create: return 'boardAdmin'
  }
  return  ""
}

export function getMfaType(type){
  if(type !== undefined && type.length >= 0){
    var sendmethod = "";
    var count = type.length;
    type.forEach((send, index)=>{
      if (index > 0)
          sendmethod += " and ";

      if (send == 1)
          sendmethod += "SMS";
      else if (send == 2)
          sendmethod += "Notification";
      else
          sendmethod += "Email";
    })

    return sendmethod
  }

  return "Email"
}

export function setDirectorCookie(viewAs = "") {
  let userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
  if(userCache){
    userCache.viewAs = viewAs
    localStorage.setItem('AppWeb', JSON.stringify(userCache))
  }
}

export function isValidURL(string) {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
};

export function handle206(api, update, args){
  return new Promise((resolve, reject) => {
    var retries = 3;
    var retryDelay = 10000;
    var opt = {'Content-Type': 'application/json'}

    var wrappedFetch = function(n) {
      api(args)
        .then(
          item => {
            resolve(item)
          },
          error => {
            if(error.code !== undefined && error.code === "F206"){
              opt['AsyncToken'] = error.asyncToken
              error.response.json().then((data) => {
                if(update !== null && update !== undefined){
                  update(data)
                }
              })
              retry(retries)
              return
            }
            reject(error)
          }
        );
    }

    function retry(n) {
      setTimeout(function() {
          wrappedFetch(--n);
        }, retryDelay);
    }

    wrappedFetch(retries);
  })
}

export async function CredentialsHash(username, profile, asArrayBuffer = false){
  const salt = username.toLowerCase()
  const profileId = profile.toLowerCase()+salt
  const v = await CrytpoLib.pbkdf2SHA512Hash(profileId, CrytpoLib.textToArrayBuffer(salt))
  return asArrayBuffer ? v : CrytpoLib.arrayBufferToBase64String(v);
}

export function AthenaLoading(){
  return (
    <div className="athena-loading">
      <span className="css-1cnp6ed-LoadingIndicator"></span>
    </div>
  )
}

export function isPDFValid(data){
  // var buffer = null
  // if(data instanceof Blob){
  //   buffer = await data.arrayBuffer();
  // }else{
  //   buffer = data
  // }
  if(data.byteLength < 5) return false
  const header = new Uint8Array(data.slice(0, 4))

  if(header[0] === 37 && header[1] === 80 && header[2] === 68 && header[3] === 70)
    return true
  return false
}

export function checkDemo(path){
  if(window.demo){
    if(window.api === undefined) window.api = []
    if(window.api.find(o => o === path)) return true
    window.api.push(path)
  }

  return false
}

export function DeviceFingerprint(page){
  return new Promise((resolve, reject)=>{
    Fingerprint2.get((result)=> {
      ReplaceValue(result, 'screenResolution', [])
      ReplaceValue(result, 'availableScreenResolution', [])
      ReplaceValue(result, 'fonts', [])

      if(appConfig.recordDeviceFingerprint === true){
        SettingStorage.Put({id: page, key: result}).then(()=>{}).catch((e)=>{console.log(e);});
      }

      var values = result.map(function (component) { return component.value })
      CrytpoLib.SHA256(Fingerprint2.x64hash128(values.join(''), 31))
      //CrytpoLib.SHA256(result.map(function (pair:any) { return pair.value; }).join())
      .then(function(hash) {
        resolve(hash)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  });
}

export const selectAthenaStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#0024ff' : 'white',
      color: isSelected ? 'white' : '#333333',
      // cursor: isDisabled ? 'not-allowed' : 'default',
      //
      // ':active': {
      //   ...styles[':active'],
      //   backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      // },
    };
  },
}

export function ReplaceValue(result, key, newValue){
  const f = result.find(o => o.key === key)
  if(f) f.value = newValue
}

export function timeoutOptions(){
  return [
    { value: 5*60, label: '5 minutes' },
    { value: 10*60, label: '10 minutes' },
    { value: 15*60, label: '15 minutes' },
    { value: 30*60, label: '30 minutes' },
    { value: 60*60, label: '1 hour' },
    { value: 2*60*60, label: '2 hours' },
    { value: 3*60*60, label: '3 hours' },
    { value: 4*60*60, label: '4 hours' },
    { value: 5*60*60, label: '5 hours' },
    { value: 6*60*60, label: '6 hours' },
    { value: 7*60*60, label: '7 hours' },
    { value: 8*60*60, label: '8 hours' },
  ]
}

export function archiveOptions(){
  return [
    { value: 0, label: 'Days after meeting' },
    { value: 1, label: 'Board year end' },
    { value: 2, label: 'End of financial year' },
    { value: 3, label: 'End of calendar year' }
  ]
}

export const MoveToPreviousOptions = [
  { value: 0, label: 'Days after meeting' },
  { value: 1, label: 'Board year end' },
  { value: 2, label: 'End of financial year' },
  { value: 3, label: 'End of calendar year' }
]

export function trackRecord(data){
  var action = data.event
  if(data.click) action = 'click'
  else if(action === undefined && data.action !== undefined) action = data.action
  var obj = {
    action: action,
    date: moment().utc().format(),
    data: JSON.stringify(data)
  };
  if(data.boardId !== undefined && data.boardId !=="")
    obj.boardId = data.boardId;
  if(data.binderId !== undefined && data.binderId !=="")
    obj.binderId = data.binderId;
  if(data.userId !== undefined && data.userId !=="")
    obj.userId = data.userId;
  if(data.objectId !== undefined && data.objectId !=="")
    obj.objectId = data.objectId;

  (window.dataAthenaLayer = window.dataAthenaLayer || []).push(obj);
  (window.simpleLayer = window.simpleLayer || []).push(JSON.stringify(obj));
  if(window.simpleLayer.length > 20)
    window.simpleLayer.shift();
}

export function CopyPDFFile(firstDonorPdfBytes, pdfDoc) {
  return new Promise((resolve, reject) => {
    log("CopyPDFFile- load pdf");
    var pIndex = 0;
    PDFDocument.load(firstDonorPdfBytes, { ignoreEncryption: true, throwOnInvalidObject: true }) //
      .then((pdf) => {
        log("CopyPDFFile- loaded pdf");
        pdfDoc.copyPages(pdf, pdf.getPageIndices())
          .then((copiedPages) => {
            log("CopyPDFFile- copy paged");
            copiedPages.forEach((page) => {
              pdfDoc.addPage(page);
            });
            log("CopyPDFFile- resolve copy");
            resolve();
          })
          .catch((e) => {
            log(`CopyPDFFile- error 1`);
            log(`CopyPDFFile- err 1 - copied pages + ${pIndex}`);
            try { log(`CopyPDFFile - error 1 ---- ${e ? JSON.stringify(e) : e}`); } catch (e) { }
            reject(e);
          })
      })
      .catch((e) => {
        log(`CopyPDFFile- error 2`);
        log(`CopyPDFFile- err 2 - copied pages + ${pIndex}`);
        try { log(`CopyPDFFile- error 2 ---- ${e ? JSON.stringify(e) : e}`); } catch (e) { }
        reject(e);
      })
  })
}

export function mergeTypedArraysUnsafe(buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
}

export const PUSHNOTICATIONTYPE = {
    CUSTOM: 0,
    BINDERADDED: 1,
    BINDERUPDATE: 2,
    BINDERREMOVE: 3,
    BINDERACCESSGANTED: 4,
    BINDERACCESSREVOKED: 5,
    BOARDFILEUPDATE: 6,
    BOARDFILEREMOVE: 7,
    BINDERADDEDSILENT: 13,
    BINDERUPDATESILENT: 14,
    BinderAddedPnsAndWs: 36,
    BinderUpdatedPnsAndWs: 37,
    BinderAddedEmail: 43,
    BinderUpdatedEmail: 44,
    BinderAddedEmailWithCalendarEvent: 59,
    BinderUpdatedEmailWithCalendarEvent: 60,
}

export function checkRecoveryCard(serial){
  if(serial.length > 17){
    serial = serial.substring(0, 17);
  }
  for(var x=0; x<serial.length; x++) {
    const c = serial[x]
    if(x == 7)
      if(c != "-") return false
      else continue
    if("0123456789ABCDEF".indexOf(c) === -1) {
      return false
    }
  }
  if(serial.length === 7){
    serial += "-"
  }

  return serial
}

export function isValidRecoveryCard(serial){
  var words = serial.toLowerCase().split("-");
  if(words.length === 2){
    if(words[0].length === 7 && words[1].length === 9){
      var regexp = /^[0-9a-fA-F]+$/;
      if(regexp.test(words[0]) && regexp.test(words[1]))
        return true;
    }
  }
  return false;
}

export function trim(string, length){
  return string.length > length ? string.substring(0, length) : string;
}

export function cyrb53Hash (str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for(let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deepClone(item) {
  if (Array.isArray(item)) {
    var newArr = [];
    for (var i = item.length; i-- > 0;) {
      newArr[i] = deepClone(item[i]);
    }
    return newArr;
  }
  if((item instanceof Date))
    return new Date(item.valueOf());
  if(moment.isMoment(item)){
    return moment(item);
  }
  /*if (typeof item === 'function' && !(/\(\) \{ \[native/).test(item.toString())) {
    var obj;
    eval('obj = '+ item.toString());
    for (var k in item) {
      obj[k] = deepClone(item[k]);
    }
    return obj;
  }*/
  if (item && typeof item === 'object') {
    var obj = {};
    for (var k in item) {
      obj[k] = deepClone(item[k]);
    }
    return obj;
  }
  return item;
}

function PassPolicyAdjustText(line){
  if(line[0] == '\t'){
    return('<div style="margin-left:12px;">*'+line.replace('\t','')+'<br/></div>');
  }
  return('<p class="hintp">'+line+'</p>');
}
/*
function regExIn(regex, position, desc){
  var g = [];

  if(regex[position] == '('){
    var bracket = position;
    var groups = "";
    for(var x=position+1; x<regex.length; x++){
      var c = regex[x];
      if(c == '\\'){
        x++;
        continue;
      }else if(c == "("){
        if(bracket == -1){
          bracket = x;
        }else{
          if(desc.length == 0) return [false, regex.length, []];
          var d = desc.splice(0,1);
          var inner = regExIn(regex, x, desc);
          g.push({
            regex: regex.slice(bracket, inner[1]+1).trim(),
            description: PassPolicyAdjustText(d),
            subItem: inner[0],
          });
          x = inner[1];
          desc = inner[2];
        }
      }else if(c == ')'){
        if(bracket != -1){
          var s = regex.slice(bracket, x+1).trim();
          groups += s;
          bracket = -1;
        }else{
          position = x;
          if(groups != ""){
            if(desc.length == 0) return [false, regex.length, []];
            g.push({
              regex: groups,
              description: PassPolicyAdjustText(desc[0]),
              subItem: null,
            });
            desc.splice(0,1);
          }
          groups = "";
          break;
        }
      }else if(c == '|'){
        if(desc.length == 0) return [false, regex.length, []];
        g.push({
          regex: groups,
          description: PassPolicyAdjustText(desc[0]),
          subItem: null,
        });
        desc.splice(0,1);
        groups = "";
      }
    }

    if(groups != ""){
      if(desc.length == 0) return [false, regex.length, []];
      g.push({
        regex: groups,
        description: PassPolicyAdjustText(desc[0]),
        subItem: null,
      });
      desc.splice(0,1);
    }
  }

  return [g, position, desc];
}*/

function regExIn(regex, position){
  var g = [];

  if(regex[position] == '('){
    var bracket = position;
    var groups = "";
    for(var x=position+1; x<regex.length; x++){
      var c = regex[x];
      if(c == '\\'){
        x++;
        continue;
      }else if(c == "("){
        if(bracket == -1){
          bracket = x;
        }else{
          var inner = regExIn(regex, x);
          g.push(regex.slice(bracket, inner[1]+1).trim());
          x = inner[1];
        }
      }else if(c == ')'){
        if(bracket != -1){
          var s = regex.slice(bracket, x+1).trim();
          groups += s;
          bracket = -1;
        }else{
          position = x;
          if(groups != ""){
            g.push(groups);
          }
          groups = "";
          break;
        }
      }else if(c == '|'){
        g.push(groups);
        groups = "";
      }
    }

    if(groups != ""){
      g.push(groups);
    }
  }

  return [g, position];
}

function regExOut(regex, description){
  var g = [];

  var desc = description.split('\n');
  var bracket = -1;
  for(var x=0; x<regex.length; x++){
    var c = regex[x];
    if(c == '\\'){
      x++;
      continue;
    }else if(c == "("){
      if(bracket == -1){
        bracket = x;
      }else{
        if(desc.length == 0) return [];
        var d = PassPolicyAdjustText(desc.splice(0,1));
        for(var y=0; y<desc.length; y++){
          if(desc[y][0] === '\t'){
            d += PassPolicyAdjustText(desc[y]);
            continue;
          }

          break;
        }
        desc.splice(0,y);
        var inner = regExIn(regex, x);
        g.push({
          regex: regex.slice(bracket, inner[1]+1).trim(),
          text: PassPolicyAdjustText(d),
          oldFormat: true,
          parts: inner[0],
        });
        if(inner[1] == regex.length && inner[0] == false) return [];

        x = inner[1];
        //desc = inner[2];
      }
    }else if(c == ')'){
      if(bracket != -1){
        if(desc.length == 0) return [];
        var s = regex.slice(bracket, x+1).trim();
        g.push({
          regex: s,
          text: PassPolicyAdjustText(desc[0]),
          oldFormat: true,
          parts: undefined,
        });
        desc.splice(0,1);
        bracket = -1;
      }
    }
  }

  if(desc.length != 0) return [];

  return g;
}

export function regexSpliter(regex, description){
  var passwordPolicy = regExOut(regex, description);

  if(passwordPolicy.length < 1){
    var des = '';
    var lines = description.split('\n');
    lines.forEach(function(line){
      des += PassPolicyAdjustText(line);
    });

    passwordPolicy.push({
      regex: regex,
      text: des,
      oldFormat: true,
      parts: undefined,
    });
  }

  return passwordPolicy;
}

export function checkForUpdate(){
  return new Promise(function(resolve, reject) {
    fetch('/index.html')
    .then((response)=>{
      if (!response.ok) {
        return Promise.reject();
      }
      return response.text();
    })
    .then((text) => {
      var w = text.split('/lib/main.bundle.js?');
      if(w.length > 1){
        var p = w[1].indexOf('"');
        if(p !== -1){
          var res = w[1].slice(0, p).trim();
//console.log("checking", res);
          var scripts = document.getElementsByTagName('script');
          if(scripts.length){
//console.log("script", scripts.length);
            var results = "";
            var founds = false;
//console.log("A");
            try{
              for(var scriptIndex in scripts) {
//console.log("B", scriptIndex, scripts[scriptIndex].innerHTML, res);
                if(scripts[scriptIndex].innerHTML !== undefined){
//console.log("D");
                  var w = scripts[scriptIndex].innerHTML.split('/lib/main.bundle.js?')
                  if(w.length > 1){
                    var p = w[1].indexOf('"');
                    if(p !== -1){
                      var res2 = w[1].slice(0, p).trim();
//console.log("E", res2, res);
                      if(res === res2){
//    console.log("C");
                        founds = true;
                        break;
                      }
                    }
                  }

                }
              }
            }catch(e){}
//console.log("found", res,founds);
            if(!founds){
//console.log("update fail", res);
              //update
              return resolve(true);
            }
          }
        }
      }
      resolve(false);
    })
    .catch(()=>{
      resolve(false);
    })
  });
}

export function getConversionTypes(){
  return ['docx','pptx','xlsx'];
}

export function getSupportTypes(){
  return "application/pdf,video/mp4,video/quicktime,video/x-quicktime,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";//,video/x-msvideo,video/mpeg,video/ogg,video/x-ms-wmv,video/x-matroska";
}

export function getSupportVideos(){
  return "video/mp4,video/quicktime,video/x-quicktime";//,video/x-msvideo,video/mpeg,video/ogg,video/x-ms-wmv,video/x-matroska"
}

export function getSupportDocuments(){
  return "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //
}

export const bulkUploadFileTypes = "text/csv,text/plain,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export function getXMLDocumentTypes() {
  return "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
}

export function getDocumentRevisionTypes() {
  return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
}

export function getSupportURL(){
  return 'www.athenaboard.com';
}

export function getSupportEmail(){
  return 'support@athenaboard.com';
}


export function checkFile(files, rejectedFiles, type,  documentConversionEnabled, binderItemSizeLimit){
  var filteredHiddenRejectedFiles = rejectedFiles.filter((f) => {
    if (f.name) {
      return Boolean(f.name)
    } else if (f.file && f.file.name) {
      return Boolean(f.file.name)
    }
    return '';
  });
  
  filteredHiddenRejectedFiles = filteredHiddenRejectedFiles.map(f => f.name ? f.name : f.file && f.file.name ? f.file.name : "").filter(f => f);

  if (filteredHiddenRejectedFiles.length > 0) {
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Unsupported File Type</h1>
          {type === FileTypeEnum.pdf?
            <p>Only PDF, Word, Powerpoint and Excel files are supported</p>
            :
            <p>Only MP4 and MOV files are supported.</p>
          }
          <p>The following files are not supported:</p>
          <ul>
            {filteredHiddenRejectedFiles.map(f => <li>{f}</li>)}
          </ul>
          <div className='boardpanel flexend'>
            <button className="btn-bg" onClick={onClose}>OK</button>
          </div>
        </div>,
    })
    //AVI, MPG, OGG, MP4, WMV and MKV
    return false;
  }

  if (files.length < 1) {
    return false;
  }
  if(files[0] === null) return false;
  if(getConversionTypes().includes(fileext(files[0].name)) &&
      (documentConversionEnabled === false || documentConversionEnabled === undefined)){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Document Conversion Disabled</h1>
          <p>This feature is currently disabled.<br/>
          Please contact Athena Board support for more information.</p>
          <div className='boardpanel flexend'>
            <button className="btn-bg" onClick={onClose}>OK</button>
          </div>
        </div>,
    })
    return false;
  }
  if(files[0].size > binderItemSizeLimit) {
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>File Size too Large</h1>
          <p>File selected is larger then 100mb.</p>
          <div className='boardpanel flexend'>
            <button className="btn-bg" onClick={onClose}>OK</button>
          </div>
        </div>,
    })
    return false;
  }
  const pattern = /^[\w!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s\u2018\u2019\u201C\u201D]*$/;
  if(!pattern.test(files[0].name)) {
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Unsupported characters found in filename</h1>
          <p>Please upload a file in English.</p>
          <div className='boardpanel flexend'>
            <button className="btn-bg" onClick={onClose}>OK</button>
          </div>
        </div>,
    })
    return false;
  }

  return true;
}

export function replaceUnsupportedCharacters(str = '') {
  try {
    str = str.replaceAll(/[\u2014\u2015]/g, '-');
    str = str.replaceAll(/[\u2018\u2019]/g, "'");
    str = str.replaceAll(/[\u201C\u201D]/g, '"');
  } catch { }
  return str;
}

export function copySettings(settings){
  var newSettings = {};
  Object.keys(settings).map(e => {
    if(e === 'daysUntilBoardDataDeletedUponLostDevice'){
//      if(!settings.immediateBoardDataDeletedUponLostDevice)
        newSettings[e] = settings[e];
    }else if(e !== 'loading' && e !== 'error' && e !== 'id')
      newSettings[e] = settings[e];
  });

  return newSettings;
}

export function GetTypeName(type){
  switch (type) {
    case UsageType.ContributorFileUploaded:
      return "Contributor file uploaded";
    case UsageType.ContributorFileRejected:
      return 'Contributor file rejected';
    case 'BoardSettingsCreated': 
      return 'Board settings created';
    case 'DownloadSpeedMeasured': 
      return 'Download speed measured';
    case UsageType.CircularResolutionRead:
      return 'Circular resolution read';
    case UsageType.CircularResolutionDownloaded:
      return 'Circular resolution downloaded';
    case UsageType.CircularResolutionPublished:
      return 'Circular resolution published';
    case UsageType.CircularResolutionCreated:
      return 'Circular resolution created';
    case UsageType.CircularResolutionUpdated:
      return 'Circular resolution updated';
    case UsageType.CircularResolutionSigned:
      return 'Circular resolution signed';
    case UsageType.CircularResolutionAbstained:
      return 'Circular resolution abstained';
    case UsageType.CircularResolutionDeleted:
      return 'Circular resolution deleted';
    case UsageType.CircularResolutionFinalised:
      return 'Circular resolution finalised';
    case UsageType.ContributorDeleted:
      return 'Contributor deleted';
    case UsageType.ContributorUpdated:
      return 'Contributor updated';
    case UsageType.ContributorCreated:
      return 'Contributor created';
    case UsageType.ContributorFileAddedToBinder:
      return 'Contributed file added to Binder';
    case UsageType.ItemCreated:
      return 'Item Created';
    case UsageType.ItemUpdated:
      return 'Item Updated';
    case UsageType.ItemDeleted:
      return 'Item Deleted';
    case UsageType.BinderCreated:
      return 'Binder Created';
    case UsageType.BinderUpdated:
      return 'Binder Updated';
    case UsageType.BinderDeleted:
      return 'Binder Deleted';
    case UsageType.BinderStatusChanged:
      return 'Binder Status Changed';
    case 'BinderExported':
      return 'Binder Exported';
    case UsageType.FileCreated:
      return 'File Created';
    case UsageType.FileUpdated:
      return 'File Updated';
    case UsageType.FileDeleted:
      return 'File Deleted';
    case UsageType.CalendarItemCreated:
      return 'Calendar Item Created';
    case UsageType.CalendarItemUpdated:
      return 'Calendar Item Updated';
    case UsageType.CalendarItemDeleted:
      return 'Calendar Item Deleted';
    case UsageType.BoardCreated:
      return 'Board Created';
    case UsageType.BoardUpdated:
      return 'Board Updated';
    case UsageType.BoardDeleted:
      return 'Board Deleted';
    case UsageType.NotesTaken:
      return 'Notes taken';
    case UsageType.FilesOpened:
      return 'Files opened';
    case UsageType.FileRead:
      return 'File read';
    case UsageType.FileDownloaded:
      return 'File downloaded';
    case UsageType.CalendarOpened:
      return 'Calendar opened';
    case UsageType.DocumentShared:
      return 'Document shared';
    case UsageType.DocumentPrinted:
      return 'Document printed';
    case UsageType.AnnotationShared:
      return 'Annotation shared';
    case UsageType.ResolutionResultDownloaded:
      return 'Resolution Result Downloaded';
    case UsageType.VoteResultDownloaded:
      return 'Vote Result Downloaded';
    case UsageType.ScreenshotTaken:
      return 'Screenshot taken';
    case UsageType.ScreenRecordedOnDevice:
      return 'Screen recorded on device';
    case UsageType.ScreenRecordedOffDevice:
      return 'Screen recorded off device';
    case 'MfaSent':
      return 'Two factor Code has been sent';
    case 'MfaSucceeded':
      return 'Two factor Code has been entered';
    case 'LoginFailed':
      return 'Login Failed';
    case 'LoginSucceeded':
      return 'Login Succeeded';
    case 'WelcomeEmailSent':
      return 'Welcome Email Sent';
    case UsageType.FormCreated:
      return 'Survey created';
    case UsageType.FormUpdated:
      return 'Survey updated';
    case UsageType.FormPublished:
      return 'Survey published';
    case UsageType.FormDeleted:
      return 'Survey deleted';
    case UsageType.FormClosed:
      return 'Survey closed';
    case UsageType.FormResponsePublished:
    case UsageType.FormResponseCreated:
      return 'Survey response created';
    case UsageType.ConflictOfInterestOpened:
      return 'Conflict of Interest opened';
    default:
      return type;
  }
}

export function capitaliseFirstLetter(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncateStringToLength(word = '', length = 40, ellipsis = true) {
  if (word.length <= length) { return word; }
  return word.substring(0, length) + (ellipsis ? '...' : '');
}

export function userNameOrder(first = '', last = '', firstNameFirst = true) {
  if (firstNameFirst || firstNameFirst === undefined) {
    return first + " " + last;
  } else {
    return last + " " + first;
  }
}

export function getNextLetter(key) {
  if (key === 'Z' || key === 'z') {
    return String.fromCharCode(key.charCodeAt() - 25) + String.fromCharCode(key.charCodeAt() - 25); // AA or aa
  } else {
    var lastChar = key.slice(-1);
    var sub = key.slice(0, -1);
    if (lastChar === 'Z' || lastChar === 'z') {
      // If a string of length > 1 ends in Z/z,
      // increment the string (excluding the last Z/z) recursively,
      // and append A/a (depending on casing) to it
      return getNextLetter(sub) + String.fromCharCode(lastChar.charCodeAt() - 25);
    } else {
      // (take till last char) append with (increment last char)
      return sub + String.fromCharCode(lastChar.charCodeAt() + 1);
    }
  }
  return key;
};

export function romanize (num) {
	if (!+num)
		return false;
	var	digits = String(+num).split(""),
		key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
		       "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
		       "","I","II","III","IV","V","VI","VII","VIII","IX"],
		roman = "",
		i = 3;
	while (i--)
		roman = (key[+digits.pop() + (i * 10)] || "") + roman;
	return Array(+digits.join("") + 1).join("M") + roman;
}

export function deromanize (str) {
	var	str = str.toUpperCase(),
		validator = /^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/,
		token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g,
		key = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},
		num = 0, m;
	if (!(str && validator.test(str)))
		return false;
	while (m = token.exec(str))
		num += key[m[0]];
	return num;
}

export function wordsContainSearchTerm(words = [], searchTerm = "", ignoreCase = true) {
  if (!Array.isArray(words)) { return false; }
  return ignoreCase
    ? words.some(w => (w || "").toLowerCase().includes(searchTerm.toLowerCase()))
    : words.some(w => (w || "").includes(searchTerm));
}

export function cmpWord(a = "", b = "", localeOptions = {}) {
  try {
    if(a === undefined || b === undefined) return 0;
    if(a.localeCompare === undefined) return 0;
    return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, { numeric: true, ...localeOptions });
  } catch (e) { log(e); return 0; }
}

export function cmpNum(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

export function cmpDate(a, b) {
  return a - b;
}

export function cmpMoment(a, b) {
  return a.valueOf() - b.valueOf()
//  return a.isAfter(b)
}

export function filterAscii(str){
  return str.replace(/[^\x20-\x7E]/g, "");
}

export function WelcomeFileName(first, last, ){
  return 'Welcome '+first+' '+last+'.pdf';
}

export function getPositionString(name){
  var p = ""
  for(var x=0; x<name.length; x++){
    const parsed = parseInt(name[x]);
    if(!isNaN(parsed) || isNaN(parsed) && (name[x] === '.')){
      p += name[x];
      continue
    }

    break
  }

  var s = p.split(".")
  if(s.length > 3) return ""
  for(var x=0; x<s.length; x++){
    if(s[x].length > 3) return ""
  }

  return s.filter(function(el){ return el !== "" }).join(".");
}

export function fileext(fname){
  return fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function basename(fname) {
    return fname.split(".").slice(0, -1).join(".");
}

export function ValidateEmail(email)
{
  if(email.length > 320) return false;
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function getDefaultBinderItem(){
  return {
    id:"",
    positionString: "",
    adminPositionString: "",
    indentCount: 0,
    position: -1,
    adminPosition: -1,
    canSubmit: false,
    canPublish: false,
    showEdit: false,
    itemId: "",
    itemName: "",
    userItems: [],
    key: '',
    documentId: '',
    oldDocumentId: '',
    hasDocument: false,
    isSaved: false,
    isSaving: false,
    isSavingSent: false,
    itemRequiresDecision: false,
    itemPresentedBy: '',
    showItemName: null,
    fileName: "",
    size: 0,
    duration: "",
    binderType: "",
    type: "",
    style: "",
    documentId: "",
    voteDes: "",
    voteAns: [],
    pageCount: 0,
    expiryDate: null,
    expirySet: false,
    date: "",
    itemdata: null,
    requestData: false,
    itemdataId: "",
    verified: null,
    verifiedItemId: "",
    multidoc: false,
    parts: [],
    content: null,
    loading: true,
    prefill: false,
    thumbImage: null,
    thumbImageId: "",
    updateDate: null,
    creationDate: null,
    createdByUserId: '',
    updatedByUserId: '',
    isChanged: false,
    pdfverified: true,
    genseckey: false,
    ref: React.createRef(),
  }
}

export function ValidateMobile(mobstr){
  var m = mobstr.trim();
  m = m.replace(/\s/g, '');
  if(m.indexOf('+61') === 0){
    m = m.replace('+61','');
    if(m.indexOf('04') !== 0) return false;
    if(m.length !== 10) return false;
  }else{
    //default is australia region
    if(m.indexOf('04') !== 0) return false;
    if(m.length !== 10) return false;
  }
  return true;
}

export function ImageDataBase64(object){
  return 'data:'+object.type+';base64,' + object.data;
}

export function TrackEvent(name, data) {
  try { SmartlookWrapper('track', { ...data, eventName: name }); } catch (e) { console.error(e); }
  // if (!appConfig.segment) return
  // if (typeof analytics == 'undefined') { return; }
  // analytics.track(name, data);
}

export async function CheckDomainReachable(url) {
  try {
      // Make a request to the URL
      const response = await fetch(url, { method: 'HEAD', mode: 'no-cors' });

      // Check if the response is successful
      if (response.ok || response.type === 'opaque') {
          return true;
      } else {
          return false;
      }
  } catch (error) {
      console.error( error);
      return false;
  }
}

export function getBinderType(type, style){
  if(type === BinderItemType.PDF && style === BinderItemType.Minutes)
    return BinderItemType.minutes;
  else if(type === BinderItemType.PDF && style === 'Meeting')
    return BinderItemType.document;
  else if(type === BinderItemType.Video && style === 'Meeting')
    return BinderItemType.video;
  else if(type === BinderItemType.PDF && style === BinderItemType.Resolution)
    return BinderItemType.resolution;
  else if(type === BinderItemType.Vote)
    return BinderItemType.vote;
  else if(type === BinderItemType.Header)
    return BinderItemType.header;
  else if(type === 'MultipleDoc')
    return 'multipleDoc';
  else return BinderItemType.document;
}

export function GetInitials(string){
  var names = string.split(' '),
  initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function ToggleClassOnElementById(elementId, className, add = true) {
  if (add) {
    AddClassOnElementById(elementId, className);
  } else {
    RemoveClassOnElementById(elementId, className);
  }
}
export function AddClassOnElementById(elementId, className) {
  var el = document.getElementById(elementId);
  if (el && el.classList) {
    el.classList.add(className);
  }
}
export function RemoveClassOnElementById(elementId, className) {
  var el = document.getElementById(elementId);
  if (el && el.classList) {
    el.classList.remove(className);
  }
}

export function GetBoardTile(image, text = 'LOGO'){
  if(image === "" && text.length <= 2 || image === "" && text === "LOGO")
    return (
      <div className="tilelogoImage boardTile-Font">
        <img className="titleLogoBlank" src={logo}/>
        <div className="titleLogoText">{text}</div>
      </div>
    )
  if(image === "loading")
  return (
    <div className="tilelogoImage boardTile-Font">
      <img className="titleLogoBlank" src={logo}/>
      <div className="titleLogoText">loading</div>
    </div>
  )
  if(image === "error")
  return (
    <div className="tilelogoImage boardTile-Font">
      <img className="titleLogoBlank" src={logo}/>
      <div className="titleLogoText">error</div>
    </div>
  )
  if(image !== "" && image !== "loading" && image !== "error")
    return <img className="tilelogoImage" src={image}/>
  return (
    <div className="tilelogoImage boardTile-Font">
      <img className="titleLogoBlank" src={logo}/>
      <div className="titleLogoText">{text}</div>
    </div>
  )
}

export function GetImageDom(image, text = 'LOGO'){
  if(image === "" && text.length <= 2)
    return <div className="boardlogo charFont NoLogo">{text}</div>
  if(image === "" && text === "LOGO")
    return <div className="boardlogo LogoFont NoLogo">{text}</div>
  if(image === "loading")
    return <div className="boardlogo LogoFont LoadingLogo">Loading</div>
  if(image === "error")
    return <div className="boardlogo LogoFont LoadingLogo">Error</div>
  if(image !== "" && image !== "loading" && image !== "error")
    return <img className="boardlogoImage" src={image}/>
  return <div className="boardlogo LogoFont NoLogo">{text}</div>
}

export function cumulativeOffset(element) {
    var top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);

    return {
        top: top,
        left: left
    };
};

export function multiSearchOr(text, searchWords){
  for(var i=0; i<searchWords.length; i++)
  {
    if(text.toLowerCase().indexOf(searchWords[i].toLowerCase()) == -1)
      return false;
  }
  return true;
}

export function isObjectsSame(v1, v2){
  function countProps(obj) {
    var count = 0;
    for (var k in obj) {
      if (obj.hasOwnProperty(k)) {
        count++;
      }
    }
    return count;
  }

  if (typeof(v1) !== typeof(v2)) {
    return false;
  }

  if (typeof(v1) === "function") {
    return v1.toString() === v2.toString();
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (countProps(v1) !== countProps(v2)) {
      return false;
    }
    var r = true;
    for (var k in v1) {
      r = isObjectsSame(v1[k], v2[k]);
      if (!r) {
        return false;
      }
    }
    return true;
  } else {
    return v1 === v2;
  }
}

export function CheckImpersonationPath(data, impersonatorId, userId, step){
  if(step === 0) return false;
  var from = data.filter(function (o) {
    return (o.impersonatorId === impersonatorId);
  });
  if(from === undefined || from.length === 0) return false;

  var short = [];
  for(var item of from){
    if(item.userId === userId){
      short.push([item]);
      continue;
    }
    var detail = CheckImpersonationPath(data, item.userId, userId, step-1);
    if(detail !== false){
      for(var direction of detail){
        var path = [item];
        path = path.concat(direction);
        short.push(path);
      }
    }
  }
  if(short.length === 0) return false;
  return short;
}

export function getUserKeyPath(data, key){
  function asyncFunc(e, last) {
    return new Promise((resolve, reject) => {
      var v_promise = null;
      if(last === undefined){
        v_promise = new Promise((resolve, reject) => {
          resolve(key);
        });
      }else{
        v_promise = new Promise((resolve, reject) => {
          CrytpoLib.importPrivateKey(last, CrytpoLib.defaultRSAAlgorithmMethod)
          .then((privatekey) => {
            resolve(privatekey);
          })
          .catch((error) => {
            reject("importrsakey "+error);
          });
        });
      }

      v_promise
      .then((key) => {
        //kImpersonatorAESKey = RSA(Your kUser, kImpersonatorWrap)
        CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(e.kImpersonatorWrap))
        .then((kImpersonatorAESKey) => {
          //Target KUser = AES(kImpersonatorWrap, Target KUser)
          CrytpoLib.AESDecrypt(kImpersonatorAESKey, CrytpoLib.base64StringToArrayBuffer(e.kUser))
          .then((TargetKUser) => {
            resolve(CrytpoLib.arrayBufferToText(TargetKUser));
          })
          .catch((error) => {
            reject("aesdecypt1 "+error);
          })
        })
        .catch((error) => {
          reject("rsadecypt "+error);
        });
      });

    });
  }

  let final = [];
  function workMyCollection(arr) {
    return arr.reduce((promise, pditem) => {
      return promise
        .then((result) => {
          if(result === false) return "failed";
          return asyncFunc(pditem, result)
            .then(result => {final.push(result); return result;} )
            .catch((error) => {
              return false;
            });
        })
        .catch((error)=>{return false;});
    }, Promise.resolve());
  }

  return new Promise((resolve, reject) => {
    workMyCollection(data)
    .then((finalpem) => {
      if(finalpem === false){
        return reject("Unable to find path");
      }
      var gkey = final[0]
      if(final.length > 2)
        gkey = final[final.length-2]
      resolve({
        kUserGenSec: gkey,
        kUserTarget: finalpem,//final[final.length-1],
      });
    });
  });
}

export function DeviceIsFireFox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export const ReadFileAsArrayBuffer = (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);

      reader.readAsArrayBuffer(file);
  });
}

export function OpenInNewTabBlob(fileName, data){
  var url = window.URL.createObjectURL(data);
  // For Firefox
  if(window.navigator && window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    window.open(url,'_blank');
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  }else{
    let newWindow = window.open('/pages/loading');
    if (newWindow.document.readyState === 'complete') {
      newWindow.location = url;
    } else {
      newWindow.onload = () => {
        newWindow.location = url;
      };
    }
  }

  setTimeout(function(){
    window.URL.revokeObjectURL(url);
  },1000);
}

export function DownloadBlob(fileName, data){
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  var url = window.URL.createObjectURL(data);
  a.href = url;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
  //Because of Edge clean up after a sec
  setTimeout(function(){
    window.URL.revokeObjectURL(url);
  },1000);
}

function isIE10OrLater(user_agent) {
  var ua = user_agent.toLowerCase();
  if (ua.indexOf('msie') === 0 && ua.indexOf('trident') === 0) {
    return false;
  }
  var match = /(?:msie|rv:)\s?([\d\.]+)/.exec(ua);
  if (match && parseInt(match[1], 10) >= 10) {
    return true;
  }
  if (ua.indexOf('trident/') > 0 || ua.indexOf('edge/') >0) {
    return true;
  }
  return false;
}

export function detectPrivateMode(callback) {
  var is_private;

  if (window.webkitRequestFileSystem) {
    window.webkitRequestFileSystem(
      window.TEMPORARY, 1,
      function() {
        is_private = false;
        callback(is_private);
        return;
      },
      function(e) {
        is_private = true;
        callback(is_private);
        return;
      }
    );
  } else if (window.indexedDB && /Firefox/.test(window.navigator.userAgent)) {
    var db;
    try {
      db = window.indexedDB.open('test');
      db.onerror = function(){
        is_private = true;
        callback(is_private);
        return;
      };
    } catch(e) {
      is_private = true;
      callback(is_private);
      return;
    }
  } else if (isIE10OrLater(window.navigator.userAgent)) {
    is_private = false;
    try {
      if (!window.indexedDB) {
        is_private = true;
        callback(is_private);
        return;
      }
    } catch (e) {
      is_private = true;
      callback(is_private);
      return;
    }
  } else if (window.localStorage && /Safari/.test(window.navigator.userAgent)) {
    try {
      window.localStorage.setItem('test', 1);
    } catch(e) {
      is_private = true;
      callback(is_private);
      return;
    }

    if (typeof is_private === 'undefined') {
      is_private = false;
      window.localStorage.removeItem('test');
      callback(is_private);
      return;
    }
  }
}

const vapidPublicKey =
  "BNoEIPsqKFLTwhZl8pOvt2S0DRu274lsNb6aLv3qAaz5CgsTOSEh0NKMZ1BpXZGGdkjLGN9JaupiAee3LL9Beyo";
const convertedVapidKey = CrytpoLib.base64StringToArrayBuffer(vapidPublicKey)

export function subscribeNotification(){
/*TODO  document.addEventListener('DOMContentLoaded', function () {
    if (!Notification) {
      return;
    }

    if (Notification.permission !== "granted")
      Notification.requestPermission().then(function(result) {
        if (result === 'denied') {
          //TODO add note or popup to warn some services will be disabled.
          return;
        }
      });
  });*/
}

export function subscribePush() {
  return new Promise(function(resolve, reject) {
    resolve()
/*TODO    //register the service worker
    //TODO ADD local and production version
    var script = '/sw.js';
    if(GetURL().includes('localhost')){
      script = scriptURL;
    }
    navigator.serviceWorker.register(script)// scriptURL needs to be in root directory to support clients mode in sw
    .then(function(registration) {
      return new Promise(function(resolve2, reject2) {
        var serviceWorker;
        if (registration.installing) {
          serviceWorker = registration.installing;
        //   console.log('Service worker installing');
        } else if (registration.waiting) {
          serviceWorker = registration.waiting;
        //   console.log('Service worker installed & waiting');
        } else if (registration.active) {
          serviceWorker = registration.active;
        //   console.log('Service worker active');
        }
        var subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        };
        if (serviceWorker.state == "activated") {
          if(registration.pushManager !== undefined)
            resolve2(registration.pushManager.subscribe(subscribeOptions));
          //else if(window.safari !== undefined)
          //  resolve2(window.safari.pushNotification.permission(subscribeOptions));
          else reject2(null);
        }else{
          //Slow it done and wait for register of service worker
          serviceWorker.addEventListener("statechange", function(e) {
            if (e.target.state == "activated") {
              // use pushManger for subscribing here.
              if(registration.pushManager !== undefined)
                resolve2(registration.pushManager.subscribe(subscribeOptions));
              else reject2(null);
            }
          });
        }
      });
    })
    .then(function(pushSubscription) {
      //console.log('PushSubscription: ', JSON.stringify(pushSubscription));
      resolve(pushSubscription);
    })
    .catch(function(err) {
      reject();
    })*/
  });
}

export function unsubscribePush() {
  return new Promise(function(resolve, reject) {
    resolve();
/*    if(!SWRegistration) resolve();
    navigator.serviceWorker.ready.then(registration => {
      //Find the registered push subscription in the service worker
      SWRegistration.pushManager
        .getSubscription()
        .then(subscription => {
          if (!subscription) {
            //If there isn't a subscription, then there's nothing to do
            resolve();
            return;
          }
          subscription
            .unsubscribe()
            .then(() => resolve())
            .catch(err => console.error(err))
        })
        .catch((err) => console.error(err))
    })*/
  });
}

export function subscribePushNotification() {
  return new Promise(function(resolve, reject) {
    resolve()
/*    subscribeNotification();
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister()
      }
      subscribePush()
      .then(function(regWebPush) {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('install', event => event.waitUntil(self.skipWaiting()));
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('activate', event => event.waitUntil(self.clients.claim()));

        resolve(regWebPush);
      })
      .catch(function() {
        reject();
      });
    }).catch((error)=>{
      console.log("subscribePushNotification error",error);
      reject();
    })*/
  });
}

export function CheckInternet(error, dispatch){
  function lostInternet() { return { type: userConstants.INTERNET_LOST } }
  if(error === 'H001')
  {
    dispatch(lostInternet());
    return "Unable to connect to Athena Board Server.";
  }
  return error;
}

export function sizeToStringVariable(bytes, round = 2) {
  if(bytes > 1023) {
    return sizeToStringShort(bytes, round);
  } else {
    return sizeToString(bytes, round);
  }
}

export function sizeToString(bytes, round = 2){
  var sizes = ['byte', 'Kilobyte', 'Megabyte', 'Gigabyte', 'Terabyte'];
  if (bytes == 0) return '0 bytes';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  var v = Math.round(bytes / Math.pow(1024, i), round);
  var p = "";
  if(v > 2) p = "s";
  return v + ' ' + sizes[i] + p;
}

export function sizeToStringShort(bytes, round = 2){
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 bytes';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  var v = Math.round(bytes / Math.pow(1024, i), round);
  var p = "";
  // if(v > 2) p = "s";
  return v + sizes[i] + p;
}

export function detectIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

export function convertCountryToCode(name){
  const country = countryCodeLookup.byCountry(name)
  let countryCode = name
  if(country !== null && country.iso2) countryCode = country.iso2
  else if(countryCode === "Palestine") countryCode = "PS"
  return countryCode
}

export function convertCodeToCountry(code) {
  let countryDetails = countryCodeLookup.byInternet(code) || countryCodeLookup.byIso(code);
  if (countryDetails && countryDetails.country) {
    return {
      country: countryDetails.country,
      isoNo: countryDetails.isoNo
    }
  } else {
    return {
      country: '',
      isoNo: ''
    }
  }
}

export function checkBadPassword(pass){
  if (!pass) { return false; }
  const password = pass.toLowerCase()
  return badlist.includes(password);
}

export function isValidMobile(mobile){
  let [country, ...number] = mobile.split(' ')
  number = number.join("")
  if((country === '+61' && number.charAt(0) === '4' && number.length === 9) || (country === '+44' && number.charAt(0) === '7' && number.length === 10) 
  || (country === '+64' && number.charAt(0) === '2' && (number.length >= 8 && number.length <= 10))){
    return true
  }else if(country === '+61' || country === '+44' || country === '+64'){
    return false
  }else{
    return true
  }
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function getBase64FileExtension(base64String = "") {
  switch (base64String.charAt(0)) {
    case '/':
      return 'jpeg';
    case 'i':
      return 'png';
    case 'R':
      return 'gif';
    case 'U':
      return 'webp';
    case 'J':
      return 'pdf';
    default:
      return 'unknown';
  }
}

export function roleOptions(){
  return [
    { value: 'Company Secretary', label: 'Company Secretary' },
    { value: 'Director', label: 'Director' },
    { value: 'Chief Financial Officer', label: 'Chief Financial Officer' },
    { value: 'Director and Administrator', label: 'Director and Administrator' },
    { value: 'Managing Director', label: 'Managing Director' },
  ]
}

export function userRoleOptions() {
  return [
    "Chairman",
    "Chief Compliance Officer",
    "Chief Executive Officer",
    "Chief Financial Officer",
    "Chief Information Officer",
    "Chief Marketing Officer",
    "Chief Operating Officer",
    "Chief Revenue Officer",
    "Chief Risk Officer",
    "Chief Technology Officer",
    "Company Group Executive",
    "Company Officer (other)",
    "Company Secretary",
    "Director (non-executive)",
    "Director and Administrator",
    "Executive Director",
    "Managing Director",
    "Managing Partner",
    "Partner",
    "President",
    "Vice President"
  ]
}

export function fixUserRole(role = '') {
  switch (role) {
    case 'director': return 'Director';
    case 'companySecretary': return 'Company Secretary';
    case 'chiefFinancialOfficer': return 'Chief Financial Officer';
    case 'directorAdmin': return 'Director and Administrator';
    case 'managingDirector': return 'Managing Director';
    default:
      return role;
  }
}

export function abbrvUserRole(role = '') {
  role = fixUserRole(role);
  switch (role) {
    case 'Chief Compliance Officer':
    case 'Chief Executive Officer':
    case 'Chief Financial Officer':
    case 'Chief Information Officer':
    case 'Chief Marketing Officer':
    case 'Chief Operating Officer':
    case 'Chief Revenue Officer':
    case 'Chief Risk Officer':
    case 'Chief Technology Officer':
    case 'Company Group Executive':
    case 'Managing Director':
    case 'Managing Partner':
    case 'Vice President':
      return role.match(/\b(\S)/g).join("");
    case 'Company Officer (other)':
      return 'CO';
    case 'Company Secretary':
      return 'Secr';
    case 'Director (non-executive)':
    case 'Director and Administrator)':
      return 'Dir';
    case 'Executive Director':
      return 'E. Dir';
    case 'President':
      return 'Pres';
    case 'Chairman':
      return 'Chm';
    case 'Partner':
      return 'PTR';
    default:
      return role.slice(0, 3);
  }
}

export function meetingFrequencyOptions(){
  return [
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'annually', label: 'Annually' }
  ]
}

export function trialFullTimeEmployeeOptions() {
  return [
    { value: '1-10', label: '1-10 employees' },
    { value: '11-50', label: '11-50 employees' },
    { value: '51-200', label: '51-200 employees' },
    { value: '201-500', label: '201-500 employees' },
    { value: '501-1000', label: '501-1000 employees' },
    { value: '1001-5000', label: '1001-5000 employees' },
    { value: '5001-10000', label: '5001-10000 employees' },
    { value: '10001+', label: '10000+ employees' },
  ]
}

export function primaryNeedOptions(){
  return [
    { value: 'Manage Board meetings with ease', label: 'Manage Board meetings with ease' },
    { value: 'Manage Boards as an outsourced company secretary', label: 'Manage Boards as an outsourced company secretary' },
    { value: 'Learn about the benefits of Athena Board', label: 'Learn about the benefits of Athena Board' },
    { value: 'Organise Board meetings for the directors', label: 'Organise Board meetings for the directors' },
    { value: 'Create packs, agendas, voting, or resolutions safely and securely', label: 'Create packs, agendas, voting, or resolutions safely and securely' },
  ]
}

export function CopyToClipBoard(string) {
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(string);
    return true;
  }
  return false;
}

export function DisableContextMenu(className = "", directorWebApp = false) {
    try {
      function contextMenuEvtListerRegistration(el) { try { el.addEventListener('contextmenu', event => event.preventDefault()); } catch { } }
      function devConsoleEvtListerRegistration(el) { try { el.addEventListener('keydown', function (e) { if (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i')) { e.preventDefault(); } }); } catch { } }
      if (directorWebApp) {
        const ClassPageEl = document.getElementsByClassName(className)[0];
        contextMenuEvtListerRegistration(ClassPageEl);
        devConsoleEvtListerRegistration(ClassPageEl);
      } else {
        contextMenuEvtListerRegistration(document.body);
        devConsoleEvtListerRegistration(window);
      }
    } catch (e) { console.log('error'); }
}

export function ReplaceSanitizedCharacters(str = "", resantize = false) {
  if (typeof str !== 'string') { return str; }
  return resantize ? str.replace(/</g, "&lt;").replace(/>/g, "&gt;") : str.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}

export function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let result = '';
  const randomValues = new Uint8Array(length);
  window.crypto.getRandomValues(randomValues);
  for (let i = 0; i < length; i++) {
    result += charset[randomValues[i] % charset.length];
  }
  return result;
}
export function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}
export function base64urlencode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
export async function generateCodeChallenge(codeVerifier) {
  const hashed = await sha256(codeVerifier);
  return base64urlencode(hashed);
}

export function sendOneTimeBroadcast(bcId, bcPayload) {
  if (!bcId) { return; }
  const bc = new BroadcastChannel(bcId);
  bc.postMessage(bcPayload);
  bc.close();
}